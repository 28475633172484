@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  table {
    @apply rounded-t-lg;
  }
  thead > tr {
    @apply bg-Gray-gray-100;
  }
  tbody > tr {
    @apply border-b border-b-Gray-gray-200;
  }
  th {
    @apply px-3 py-[10px] text-left text-sm font-semibold text-Gray-gray-500;
  }
  td {
    @apply px-3 py-[14px] text-sm text-Gray-gray-800;
  }
  tfoot {
    @apply pt-4;
  }
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/public/fonts/PlusJakartaSans-VariableFont_wght.ttf') format('truetype'),
    url('/public/fonts/PlusJakartaSans-Italic-VariableFont_wght.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Plus Jakarta Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.dot-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23C6C7C2FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
